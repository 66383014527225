<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <base-material-card>
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">
          Choix
        </div>
      </template>

      <v-container class="py-0">
        <v-row>
          <v-col
            cols="6"
            md="3"
          >
            <v-btn
              color="success"
              class="mr-0"
              @click="ShowVehicule()"
            >
              <v-icon
                class="mr-2"
                background-color="success"
              >
                mdi-car-estate
              </v-icon>
              Vehicule
            </v-btn>
          </v-col>
          <v-col
            cols="6"
            md="3"
          >
            <v-btn
              color="orange"
              class="mr-0"
              @click="ShowJantes()"
            >
              <v-icon
                class="mr-2"
                background-color="success"
              >
                mdi-circle-double
              </v-icon>
              Jantes
            </v-btn>
          </v-col>
          <v-col
            cols="6"
            md="3"
          >
            <v-btn
              color="warning"
              class="mr-0"
              @click="ShowPneu()"
            >
              <v-icon
                class="mr-2"
                background-color="success"
              >
                mdi-circle-slice-8
              </v-icon>
              Pneu
            </v-btn>
          </v-col>
          <v-col
            cols="6"
            md="3"
          >
            <v-btn
              color="info"
              class="mr-0"
              @click="ShowSite()"
            >
              <v-icon
                class="mr-2"
                background-color="success"
              >
                mdi-sitemap
              </v-icon>
              Site
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </base-material-card>
    <!-- ICI SONT LES CONFIGURATION VEHICULE  -->
    <base-material-card v-show="vehicule===true">
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">
          Configuration Vehicule
        </div>
      </template>
      <div>
        <form>
          <v-simple-table>
            <thead>
              <tr>
                <th class="primary--text">
                  Nom
                </th>
                <th class="primary--text">
                  Valeur
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="OneConfigVehicule in ConfigVehicule"
                :key="OneConfigVehicule.id"
              >
                <td> {{ OneConfigVehicule.Nom_config }} </td>
                <td> {{ OneConfigVehicule.valeur_config }} </td>
                <td>
                  <v-btn
                    @click="GestionTypeVehicule(vehicule)"
                  >
                    Modifier
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </form>
      </div>
    </base-material-card>
    <!-- ICI SONT LES CONFIGURATION JANTES  -->
    <base-material-card v-show="jantes===true">
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">
          Configuration Jantes
        </div>
      </template>
      <div>
        <form>
          <v-simple-table>
            <thead>
              <tr>
                <th class="primary--text">
                  Nom
                </th>
                <th class="primary--text">
                  Valeur
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="OneConfigJante in ConfigJante"
                :key="OneConfigJante.id"
              >
                <td> {{ OneConfigJante.Nom_config }} </td>
                <td> {{ OneConfigJante.valeur_config }} </td>
                <td>
                  <v-btn
                    v-show="OneConfigJante.Nom_config==='CouleursPrincipales'"
                    @click="GestionCouleurs()"
                  >
                    Gestion des couleurs
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </form>
      </div>
    </base-material-card>
    <!-- ICI SONT LES CONFIGURATION PNEU  -->
    <base-material-card v-show="pneu===true">
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">
          Configuration Pneu
        </div>
      </template>
      <div>
        Pas de Configuration Pneu
      </div>
    </base-material-card>
    <!-- ICI SONT LES CONFIGURATION SITE  -->
    <base-material-card v-show="site===true">
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">
          Configuration Site
        </div>
      </template>
      <div>
        Pas de Configuration Site
      </div>
    </base-material-card>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import VueAxios from 'vue-axios'
  import Vue from 'vue'
  Vue.use(VueAxios, axios)
  export default {
    data: () => ({
      modification: '',
      ConfigVehicule: [],
      ConfigJante: [],
      ConfigPneu: [],
      ConfigSite: [],
      vehicule: false,
      jantes: false,
      pneu: false,
      site: false,
    }),
    mounted () {
      console.log(this.$localStorage.get('connected'))
      if (this.$localStorage.get('connected') == null) {
        this.$router.push({ path: '/connexion' })
      }

      Vue.axios.get('https://api.espace-jantes.com/GetConfigJantes')
        .then((response) => {
          response.data.forEach(element => {
            this.ConfigJante.push(element)
          })
        })
        .catch(error => console.log(error))
      Vue.axios.get('https://api.espace-jantes.com/GetConfigPneu')
        .then((response) => {
          console.log('GetConfigPneu')
        })
        .catch(error => console.log(error))
      Vue.axios.get('https://api.espace-jantes.com/GetConfigSite')
        .then((response) => {
          console.log('GetConfigSite')
        })
        .catch(error => console.log(error))
      Vue.axios.get('https://api.espace-jantes.com/GetConfigVehicules')
        .then((response) => {
          response.data.forEach(element => {
            this.ConfigVehicule.push(element)
          })
        })
        .catch(error => console.log(error))
    },
    methods: {
      ShowVehicule: function () {
        this.jantes = false
        this.pneu = false
        this.site = false

        if (this.vehicule === false) {
          this.vehicule = true
        } else {
          this.vehicule = false
        }
      },
      ShowJantes: function () {
        this.vehicule = false
        this.pneu = false
        this.site = false

        if (this.jantes === false) {
          this.jantes = true
        } else {
          this.jantes = false
        }
      },
      ShowPneu: function () {
        this.vehicule = false
        this.jantes = false
        this.site = false
        if (this.pneu === false) {
          this.pneu = true
        } else {
          this.pneu = false
        }
      },
      ShowSite: function () {
        this.jantes = false
        this.vehicule = false
        this.pneu = false
        if (this.site === false) {
          this.site = true
        } else {
          this.site = false
        }
      },
      GestionTypeVehicule () {
        this.$router.push({ name: 'GestionTypeVehicule' })
      },
      modifierJantes (jantes) {
        this.modification = 'Jantes'
        if (jantes.indexOf(this.modification) === -1) {
          jantes.push(this.modification)
        }
        console.log(jantes)
      },
      modifierPneu (pneu) {
        this.modification = 'Pneu'
        if (pneu.indexOf(this.modification) === -1) {
          pneu.push(this.modification)
        }
        console.log(pneu)
      },
      modifierSite (site) {
        this.modification = 'Site'
        if (site.indexOf(this.modification) === -1) {
          site.push(this.modification)
        }
        console.log(site)
      },
      GestionCouleurs () {
        this.$router.push({ name: 'GestionCouleurs' })
      },
    },
  }
</script>
